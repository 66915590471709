@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Aldrich&display=swap);
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f6eed4;
  width: 100%;
  height: 100%;
  color: #132743;
  font-family: "Aldrich", sans-serif;
}

.App {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Navbar {
  width: 100%;
  background-color: #f6eed4;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.Navbar * {
  -webkit-user-select: none;
          user-select: none;
}

.Navbar__container {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 30px 0px;
  border-bottom: 1px solid #132743;
}

.Navbar__logoContainer {
  height: 40px;
  padding-left: 15px;
  display: flex;
}

.Navbar__logoContainer img {
  height: 100%;
}

.Navbar__menuContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.NavbarMenu {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.NavbarMenu__item {
  margin-left: 1.5rem;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.NavbarMenu__item a {
  text-decoration: none;
  color: #132743;
}

.NavbarMenu__item:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media only screen and (max-width: 900px) {
  .Navbar__container {
    width: 100%;
    padding: 20px 0px;
  }
  .Navbar__menuContainer {
    padding-right: 1rem;
    height: 100%;
  }
  .Navbar__menuContainer .hamBurger {
    width: 36px;
    height: 4px;
    background-color: #132743;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
  .Navbar__menuContainer .hamBurger::before {
    content: "";
    width: 36px;
    height: 4px;
    position: absolute;
    /* margin-bottom: 30px; */
    background-color: #132743;
    border-radius: 5px;
    -webkit-transform: translate(0px, 0.5rem);
            transform: translate(0px, 0.5rem);
    transition: all 0.2s ease-in-out;
  }
  .Navbar__menuContainer .hamBurger::after {
    content: "";
    width: 36px;
    height: 4px;
    position: absolute;
    /* margin-top: 30px; */
    background-color: #132743;
    border-radius: 5px;
    -webkit-transform: translate(0px, -0.5rem);
            transform: translate(0px, -0.5rem);
    transition: all 0.2s ease-in-out;
  }
  .Navbar__menuContainer .hamBurger--open {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
  }
  .Navbar__menuContainer .hamBurger--open::before {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
  }
  .Navbar__menuContainer .hamBurger--open::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: all 0.2s ease-in-out;
  }
  .NavbarMenu {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    padding: 0;
    position: absolute;
    left: 0;
    width: 100%;
    top: 5rem;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease-in-out;
    justify-content: flex-start;
    margin: 0;
    display: flex;
    overflow: hidden;
  }
  .NavbarMenu__item {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin: 0;
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    border-bottom: 1px solid #f6eed4;
  }
  .NavbarMenu__item a {
    background-color: #132743;
    display: inline-block;
    width: 100%;
    padding: 1rem 1rem;
    color: #f6eed4;
    text-decoration: none;
  }
  .NavbarMenu__item:last-child {
    width: 100%;
    border-bottom: none;
  }
  .NavbarMenu__item:last-child a {
    background-color: #9ad3bc;
    color: #132743;
  }
  .NavbarMenu__item:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Footer * {
  -webkit-user-select: none;
          user-select: none;
}

.Footer__content {
  width: 80%;
  border-top: 1px solid #132743;
  display: flex;
}

.Footer__content .socialHandles {
  margin: 2rem 0rem;
  width: 50%;
  display: flex;
  align-items: center;
}

.Footer__content .socialHandles__head {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  margin: 0;
  margin-right: 1rem;
  color: #132743;
}

.Footer__content .socialHandles__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0.5rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.Footer__content .socialHandles__icon:hover {
  -webkit-animation: shake_icon 0.1s linear 2;
          animation: shake_icon 0.1s linear 2;
}

.Footer__content .socialHandles__icon img {
  width: 25px;
}

.Footer__content .socialHandles__icon img:hover {
  width: 30px;
}

.Footer__content .creditsCopyrights {
  width: 50%;
  margin: 2rem 0rem;
  display: flex;
  margin: 2rem 0rem;
  justify-content: flex-end;
}

.Footer__content .creditsCopyrights__head {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #132743;
  margin: 0;
}

@-webkit-keyframes shake_icon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes shake_icon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@media only screen and (max-width: 900px) {
  .Footer__content {
    width: 100%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: center;
  }
  .Footer__content .socialHandles {
    width: 100%;
    justify-content: center;
    margin: 1rem 0rem;
  }
  .Footer__content .creditsCopyrights {
    width: 100%;
    display: flex;
    margin: 0rem 0rem;
    margin-bottom: 1rem;
    justify-content: center;
  }
}

.tagName, .tagName a {
  color: #008184;
  font-family: "Aldrich", sans-serif;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

button {
  background-color: #008184;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  cursor: pointer;
  font-family: "Space Grotesk", sans-serif;
  color: #f6eed4;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
}

button:hover {
  opacity: 0.8;
}

button:disabled {
  opacity: 0.3;
}

button:disabled:hover {
  opacity: 0.3;
  cursor: not-allowed;
}

.pageContainer {
  padding-top: 8rem;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 10rem;
}

.pageContent {
  display: flex;
  width: 80%;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  .pageContainer {
    padding-top: 5rem;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 10rem;
  }
  .pageContent {
    display: flex;
    width: 90%;
    height: 100%;
  }
}

.highLighted {
  color: #008184;
}

.Modal {
  z-index: 1000;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal__content {
  width: 50%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .Modal__content {
    width: 90%;
    height: 100vh;
  }
}

.CookiesPopup {
  display: flex;
  padding: 1rem;
  bottom: 1rem;
  max-width: 520px;
  min-height: 200px;
  position: fixed;
  z-index: 300000;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  -webkit-animation: popup 1s linear;
          animation: popup 1s linear;
}

.CookiesPopup .closeButton {
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.CookiesPopup .closeButton:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.CookiesPopup__content {
  padding: 1rem;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #132743;
  border-radius: 0.5rem;
}

.CookiesPopup__content .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.CookiesPopup__content .header img {
  width: 10%;
  max-width: 30px;
  margin: 0rem 0.5rem;
}

.CookiesPopup__content .header h3 {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  width: 85%;
  text-align: left;
  color: #132743;
}

.CookiesPopup__content .controls button {
  color: white;
  font-size: 1.125rem;
  width: 100%;
}

@-webkit-keyframes popup {
  from {
    bottom: -100vh;
  }
  to {
    bottom: 1rem;
  }
}

@keyframes popup {
  from {
    bottom: -100vh;
  }
  to {
    bottom: 1rem;
  }
}

.HomePage * {
  -webkit-user-select: none;
          user-select: none;
}

.HomePage__content {
  overflow: hidden;
  height: 80vh;
}

.HomePage__contentAbout {
  display: flex;
  width: 50%;
  flex-direction: column;
  min-width: 600px;
  margin-top: 7rem;
  height: auto;
  transition: all 1s ease-in-out;
}

.HomePage__contentAbout .heroText {
  width: 80%;
  color: #132743;
  font-family: "Aldrich", sans-serif;
  font-weight: 400;
  font-size: 4rem;
  margin: 0;
  text-align: left;
}

.HomePage__contentAbout .descText {
  width: 90%;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #747474;
  text-align: left;
}

.HomePage__contentGraphics {
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.HomePage__contentGraphics .heroImage {
  height: 80%;
  z-index: 2;
}

.HomePage__contentGraphics .imageRotator {
  top: 5%;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  list-style: none;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 7rem;
}

.HomePage__contentGraphics .imageRotator .circle {
  width: 100%;
  height: 100%;
  background-color: #9ad3bc;
  border-radius: 50%;
}

.HomePage__contentGraphics .imageRotator img {
  position: absolute;
}

.HomePage__contentGraphics .imageRotator img:nth-child(1) {
  width: 50%;
  top: 0%;
  left: 0%;
}

.HomePage__contentGraphics .imageRotator img:nth-child(2) {
  width: 35%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  bottom: 0%;
}

.HomePage__contentGraphics .imageRotator img:nth-child(3) {
  width: 45%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: 0%;
  right: 0%;
}

@-webkit-keyframes rotater {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotater {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@media only screen and (max-width: 900px) {
  .HomePage__content {
    width: 90%;
    flex-direction: column-reverse;
    height: 100%;
  }
  .HomePage__contentAbout {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-width: 100px;
    margin-top: 1rem;
  }
  .HomePage__contentAbout .heroText {
    width: 100%;
    color: #132743;
    font-family: "Aldrich", sans-serif;
    font-weight: 400;
    font-size: 2.5rem;
    margin: 0;
    text-align: left;
  }
  .HomePage__contentAbout .descText {
    width: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 500;
    font-size: 1.125rem;
    color: #747474;
    text-align: left;
  }
  .HomePage__contentGraphics {
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: flex-end;
    position: relative;
  }
  .HomePage__contentGraphics .heroImage {
    width: 60%;
    z-index: 2;
  }
  .HomePage__contentGraphics .imageRotator {
    top: 5%;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    list-style: none;
  }
}

.projectCard {
  min-width: 300px;
  width: 20rem;
  height: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.projectCard:hover {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.projectCard__imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  position: relative;
}

.projectCard__imageContainer .loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
}

.projectCard__imageContainer .loader img {
  height: 50px;
  width: 50px;
  max-width: 100px;
  max-height: 100px;
  -webkit-animation: grow-in-out 1s linear infinite;
          animation: grow-in-out 1s linear infinite;
}

.projectCard__imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
}

.projectCard__descContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.projectCard__descContainer .projectName {
  margin: 0;
  text-align: left;
  font-family: "Aldrich", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28.43px;
  line-height: 98%;
  color: #132743;
}

.projectCard__descContainer .projectCategoryDate {
  display: flex;
  margin-top: 1rem;
}

.projectCard__descContainer .projectCategoryDate .category {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 23px;
  color: #008184;
  margin-right: 0.5rem;
}

.projectCard__descContainer .projectCategoryDate .date {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 23px;
  color: #008184;
  border-left: 2px solid;
  padding-left: 0.5rem;
}

.projectCard__descContainer .projectshortDesc {
  text-align: left;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #747474;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.smallCard .projectCard__imageContainer {
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
}

.smallCard .projectCard__imageContainer img {
  top: 0%;
  width: 100%;
}

.smallCard .projectCard__imageContainer .overlay {
  display: none;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(180deg, #132743 0%, rgba(255, 255, 255, 0) 100%), #c4c4c4;
}

.smallCard .projectCard__descContainer {
  flex-direction: column;
}

.smallCard .projectCard__descContainer .projectName {
  font-size: 1.125rem;
  line-height: 121.3%;
  color: #132743;
  margin-bottom: 1rem;
}

.smallCard .projectCard__descContainer .projectCategoryDate .category {
  font-size: 0.875rem;
  line-height: 18px;
}

.smallCard .projectCard__descContainer .projectCategoryDate .date {
  font-size: 0.875rem;
  line-height: 18px;
}

.smallCard .projectCard__descContainer .projectshortDesc {
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin: 0;
  -webkit-line-clamp: 2;
}

@-webkit-keyframes grow-in-out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes grow-in-out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.ProjectsPage__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ProjectsPage__thingsPreview {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
}

.ProjectsPage__thingsPreview .thingsBannerBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  border-radius: 1.5rem;
  background-color: #9ad3bc;
}

.ProjectsPage__thingsPreview .thingsBannerBg .artwork {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  -webkit-animation: scrollLeft 40s ease-in-out infinite;
          animation: scrollLeft 40s ease-in-out infinite;
}

.ProjectsPage__thingsPreview .content {
  z-index: 1;
  width: 100%;
}

.ProjectsPage__thingsPreview .content .heading {
  color: #132743;
  font-family: "Aldrich", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2rem;
  line-height: 93%;
  margin: 0;
  margin-top: 7rem;
  text-align: center;
}

.ProjectsPage__thingsPreview .content .projectsPreview {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.ProjectsPage__thingsPreview .content .projectsPreview .projectCard {
  width: 20%;
}

.ProjectsPage__allProjects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.ProjectsPage__allProjects .headContent {
  width: 100%;
  margin-top: 7rem;
  padding: 1.5rem 0rem;
  border-bottom: 1px solid #132743;
}

.ProjectsPage__allProjects .headContent .heading {
  color: #132743;
  font-family: "Aldrich", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 93%;
  margin: 0;
}

.ProjectsPage__allProjects .projectsContent {
  margin-top: 4.5rem;
}

.ProjectsPage__allProjects .projectsContent .projectsList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2%;
  gap: 2%;
  justify-content: flex-start;
}

.ProjectsPage__allProjects .projectsContent .projectsList .smallCard {
  min-width: 200px;
  width: 18%;
  margin-bottom: 3.5rem;
}

@-webkit-keyframes scrollLeft {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes scrollLeft {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media only screen and (max-width: 900px) {
  .ProjectsPage__thingsPreview {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .ProjectsPage__thingsPreview .thingsBannerBg {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    height: 40vh;
  }
  .ProjectsPage__thingsPreview .content .projectsPreview {
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
  }
  .ProjectsPage__thingsPreview .content .projectsPreview .projectCard {
    margin-bottom: 1rem;
  }
  .ProjectsPage__allProjects .headContent {
    width: 100%;
    margin-top: 7rem;
    padding: 1.5rem 0rem;
    border-bottom: 1px solid #132743;
  }
  .ProjectsPage__allProjects .projectsContent {
    margin-top: 4.5rem;
  }
  .ProjectsPage__allProjects .projectsContent .projectsList {
    width: 100%;
    grid-gap: unset;
    gap: unset;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 0;
  }
  .ProjectsPage__allProjects .projectsContent .projectsList .smallCard {
    width: 48%;
    min-width: 100px;
  }
}

.ProjectPreviewPage__content {
  flex-direction: column;
}

.ProjectPreviewPage__projectInfo {
  margin-top: 1rem;
  display: flex;
  width: 100%;
}

.ProjectPreviewPage__projectInfo .leftContainer {
  width: 60%;
  min-width: 60%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel {
  width: 100%;
  padding-top: 56.25%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  position: relative;
  border-radius: 8px;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__indicators {
  width: 100%;
  position: absolute;
  bottom: -50px;
  display: flex;
  justify-content: center;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__indicators .dot {
  width: 1rem;
  height: 1rem;
  background-color: #cdcaca;
  border-radius: 50%;
  margin: 0.5rem;
  cursor: pointer;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__indicators .dot--active {
  background-color: gray;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__slide {
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__slide img {
  position: absolute;
  top: 0;
  height: 100%;
  width: auto;
  border-radius: 8px;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__slide iframe {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__controls {
  position: absolute;
  top: 48%;
  display: flex;
  justify-content: space-between;
  z-index: 5;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__controls img {
  width: 24px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: white;
  border-radius: 0.25rem;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel__controls img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel .prevButton {
  left: 0;
  padding-left: 1rem;
}

.ProjectPreviewPage__projectInfo .leftContainer .carousel .nextButton {
  padding-right: 1rem;
  right: 0;
}

.ProjectPreviewPage__projectInfo .leftContainer .projectDescContainer {
  width: 100%;
}

.ProjectPreviewPage__projectInfo .leftContainer .projectDescContainer .projectCategoryDate > h3 {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: #008184;
  margin-bottom: 0.5rem;
}

.ProjectPreviewPage__projectInfo .leftContainer .projectDescContainer .projectTitle {
  margin: 0;
  font-family: "Aldrich", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  color: #132743;
}

.ProjectPreviewPage__projectInfo .leftContainer .projectDescContainer .projectDescription {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #747474;
  text-align: left;
}

.ProjectPreviewPage__projectInfo .rightContainer {
  width: 40%;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectResources {
  padding-bottom: 2rem;
  border-bottom: 1px solid #132743;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .heading,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .heading {
  font-family: "Aldrich", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  margin-top: 0;
  color: #132743;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .linkButton,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .linkButton {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0.8rem 0rem;
  text-decoration: none;
  color: #132743;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .linkButton img,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .linkButton img {
  width: 32px;
  height: 32px;
  overflow: hidden;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .linkButton span,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .linkButton span {
  margin: 0;
  width: 90%;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #132743;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .resourcesContent,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .resourcesContent {
  display: flex;
  flex-wrap: wrap;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .resourcesContent .resourcesContent__desc,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .resourcesContent .resourcesContent__desc {
  width: 50%;
  margin-bottom: 1rem;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .resourcesContent .resourcesContent__desc h3,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .resourcesContent .resourcesContent__desc h3 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.125rem;
  margin: 0;
  color: #132743;
}

.ProjectPreviewPage__projectInfo .rightContainer .projectLinks .resourcesContent .resourcesContent__desc h4,
.ProjectPreviewPage__projectInfo .rightContainer .projectResources .resourcesContent .resourcesContent__desc h4 {
  /* padding-right: 1rem; */
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  color: #747474;
}

.ProjectPreviewPage__projectInfo .rightContainer .otherLinks {
  padding-bottom: 2rem;
  border-bottom: 1px solid #132743;
}

.ProjectPreviewPage__projectInfo .rightContainer .otherLinks .heading {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-size: 18px;
  color: #132743;
  margin-bottom: 1rem;
}

.ProjectPreviewPage__projectInfo .rightContainer .otherLinks .linkHeading {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-size: 18px;
  color: #132743;
}

.ProjectPreviewPage__projectInfo .rightContainer .otherLinks .link {
  word-break: break-all;
  text-decoration: none;
  color: #008184;
  font-family: "Aldrich", sans-serif;
}

.ProjectPreviewPage__similarProjects {
  margin-top: 7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ProjectPreviewPage__similarProjects .heading {
  width: 100%;
  font-family: "Aldrich", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  text-align: center;
}

.ProjectPreviewPage__similarProjects .projectsList {
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding: 2rem 0rem;
  justify-content: flex-start;
}

.ProjectPreviewPage__similarProjects .projectsList .smallCard {
  margin: 0rem 1rem;
  min-width: 200px;
  width: 18%;
}

@media only screen and (max-width: 900px) {
  .ProjectPreviewPage__content {
    flex-direction: column;
  }
  .ProjectPreviewPage__projectInfo {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ProjectPreviewPage__projectInfo .leftContainer {
    width: 100%;
  }
  .ProjectPreviewPage__projectInfo .rightContainer {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid #132743;
    width: 100%;
  }
  .ProjectPreviewPage__similarProjects {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ProjectPreviewPage__similarProjects .heading {
    width: 100%;
  }
  .ProjectPreviewPage__similarProjects .projectsList {
    width: 100%;
    display: flex;
    overflow-x: none;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    justify-content: space-between;
  }
  .ProjectPreviewPage__similarProjects .projectsList .smallCard {
    width: 48%;
    min-width: 100px;
    margin: 0;
    margin-bottom: 3.5rem;
  }
}

.Portfolio * {
  -webkit-user-select: none;
          user-select: none;
}

.Portfolio__projectsList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  justify-content: space-between;
}

.Portfolio__projectsList .PortfolioProjectCard {
  width: 45%;
}

.PortfolioProjectCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.PortfolioProjectCard img {
  width: 100%;
  border-radius: 0.5rem;
}

.PortfolioProjectCard .projectName {
  font-family: "Aldrich", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  color: #132743;
}

.PortfolioProjectCard .projectTechnologies {
  color: #132743;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
}

.PortfolioProjectCard .projectShortDesc {
  margin: 0;
  color: #5c5c5c;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
}

@media only screen and (max-width: 900px) {
  .Portfolio * {
    -webkit-user-select: none;
            user-select: none;
  }
  .Portfolio__projectsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
  }
  .Portfolio__projectsList .PortfolioProjectCard {
    width: 100%;
  }
}

.PortfolioProjectPreviewPage * {
  -webkit-user-select: none;
          user-select: none;
}

.PortfolioProjectPreviewPage__content {
  flex-direction: column;
}

.PortfolioProjectPreviewPage__content .projectInfo {
  margin: 4rem 0rem;
  display: flex;
}

.PortfolioProjectPreviewPage__content .projectInfo__content {
  width: 30%;
  min-width: 30%;
}

.PortfolioProjectPreviewPage__content .projectInfo__content .projectName {
  font-family: "Aldrich", sans-serif;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  color: #132743;
}

.PortfolioProjectPreviewPage__content .projectInfo__content .projectRoleYear {
  margin: 0;
  margin-top: 1rem;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #008184;
}

.PortfolioProjectPreviewPage__content .projectInfo__content .projectDescription {
  max-width: 350px;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1rem;
  color: #132743;
}

.PortfolioProjectPreviewPage__content .projectInfo__heroImage {
  position: relative;
  width: 70%;
  display: flex;
  justify-content: flex-end;
}

.PortfolioProjectPreviewPage__content .projectInfo__heroImage img {
  height: auto;
  width: 95%;
}

.PortfolioProjectPreviewPage__content .projectChallenge {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0rem;
}

.PortfolioProjectPreviewPage__content .projectChallenge .heading {
  width: 100%;
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 2.5rem;
  margin-top: 1rem;
  color: #132743;
}

.PortfolioProjectPreviewPage__content .projectChallenge .subHeading {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.5rem;
  margin: 0;
  color: #132743;
}

.PortfolioProjectPreviewPage__content .projectChallenge .description {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.2rem;
  width: 50%;
  margin-bottom: 3rem;
  color: #132743;
}

.PortfolioProjectPreviewPage__content .projectChallenge img {
  width: 90%;
  margin-bottom: 4rem;
  border-radius: 1rem;
}

.PortfolioProjectPreviewPage__content .projectChallenge .summary {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.PortfolioProjectPreviewPage__content .projectChallenge .summary .summaryProp {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.PortfolioProjectPreviewPage__content .projectChallenge .summary .summaryProp .tech,
.PortfolioProjectPreviewPage__content .projectChallenge .summary .summaryProp .work {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.4rem;
  margin-top: 0;
  color: #008184;
  text-decoration: none;
}

.PortfolioProjectPreviewPage__content .projectChallenge .summary .summaryProp .link {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.4rem;
  margin-top: 0;
  color: #008184;
}

.PortfolioProjectPreviewPage__content .projectChallenge .summary .summaryProp .link a {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.4rem;
  margin-top: 0;
  color: #008184;
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  .PortfolioProjectPreviewPage * {
    -webkit-user-select: none;
            user-select: none;
  }
  .PortfolioProjectPreviewPage__content {
    flex-direction: column;
  }
  .PortfolioProjectPreviewPage__content .projectInfo {
    margin: 2rem 0rem;
    display: flex;
    flex-direction: column-reverse;
  }
  .PortfolioProjectPreviewPage__content .projectInfo__content {
    width: 100%;
    min-width: 100%;
  }
  .PortfolioProjectPreviewPage__content .projectInfo__heroImage {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .PortfolioProjectPreviewPage__content .projectInfo__heroImage img {
    height: auto;
    width: 100%;
  }
  .PortfolioProjectPreviewPage__content .projectChallenge {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem 0rem;
  }
  .PortfolioProjectPreviewPage__content .projectChallenge .heading {
    width: 100%;
    text-align: left;
    font-family: "Space Grotesk", sans-serif;
    font-size: 2.5rem;
    margin-top: 1rem;
    color: #132743;
  }
  .PortfolioProjectPreviewPage__content .projectChallenge .subHeading {
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.5rem;
    margin: 0;
    color: #132743;
  }
  .PortfolioProjectPreviewPage__content .projectChallenge .description {
    font-family: "Space Grotesk", sans-serif;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 0;
    color: #132743;
  }
  .PortfolioProjectPreviewPage__content .projectChallenge img {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 1rem;
  }
}

.GiveAwaysPage__content {
  flex-direction: column;
  justify-content: center;
}

.GiveAwaysPage__content .heading {
  text-align: center;
  width: 100%;
}

.GiveAwaysPage__content__listing {
  width: 100%;
  height: 100%;
}

.NewsLetterForm {
  width: 100%;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  max-width: 560px;
  border-radius: 0.25rem;
  -webkit-animation: fadeIn 0.5s linear;
          animation: fadeIn 0.5s linear;
}

.NewsLetterForm .heading {
  font-size: 1.5rem;
  margin: 0;
  font-family: "Aldrich", sans-serif;
  font-weight: 400;
}

.NewsLetterForm .sub-heading {
  font-size: 1.25rem;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.75rem;
}

.NewsLetterForm .descriptionText {
  font-size: 1.125rem;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  line-height: 140%;
  margin: 0;
  margin-bottom: 2rem;
  color: #747474;
}

.NewsLetterForm__container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.NewsLetterForm__container--left {
  position: relative;
  width: 35%;
  background-color: #008184;
  padding: 1.25rem;
  color: white;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.NewsLetterForm__container--left .imageContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px;
  display: flex;
}

.NewsLetterForm__container--left img {
  width: 100%;
}

.NewsLetterForm__container--right {
  width: 65%;
  padding: 1.25rem;
}

.NewsLetterForm__container--right .form-fields {
  display: flex;
  flex-direction: column;
}

.NewsLetterForm__container--right .form-fields label {
  font-size: 1.25rem;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.NewsLetterForm__container--right .form-fields input {
  border: 1px solid #132743;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
}

.NewsLetterForm__container--right .form-fields input::-webkit-input-placeholder {
  color: #c7c7c7;
}

.NewsLetterForm__container--right .form-fields input::placeholder {
  color: #c7c7c7;
}

.NewsLetterForm__container--right .form-fields button {
  width: 100%;
  color: white;
}

.NewsLetterForm .closeButton {
  position: absolute;
  top: -15%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.NewsLetterForm .closeButton:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.NewsLetterForm .closeButtonOld {
  position: absolute;
  top: -20%;
  border-radius: 50%;
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  transition: all 0.3s ease-in-out;
}

.NewsLetterForm .closeButtonOld:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.NewsLetterForm .closeButtonOld::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 4px;
  background-color: white;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 2px;
}

.NewsLetterForm .closeButtonOld::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 4px;
  background-color: white;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 2px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 900px) {
  .NewsLetterForm__container--left {
    display: none;
  }
  .NewsLetterForm__container--right {
    width: 100%;
    padding: 1.25rem;
  }
}
/*# sourceMappingURL=index.css.map */
